<template>
  <div class="home">
    <!-- Header Bar -->
    <div>
      <b-overlay :show="loading" rounded="sm">
        <b-card>
          <b-row class="my-n2  ml-0 mr-0">
            <!-- Left side Icons -->

            <b-col cols="10" class="pb-2 pt-2">
              <div class="row">
                <!-- 01 Allocated leave days -->

                <div class="d-flex align-items-center pr-bx">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="CalendarIcon" size="18" />
                  </b-avatar>

                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder " v-if="trackLeaveOnDays">
                      {{ allocatedLeaveDays }} days
                    </h4>
                    <h4 class="mb-0 font-weight-bolder " v-else>
                      {{
                        parseFloat(allocatedLeaveHours).toFixed(1)
                      }}hrs
                    </h4>
                    <small>Allocated leave days</small>
                  </div>
                </div>

                <!-- 02 Used -->

                <div class="d-flex align-items-center pr-bx">
                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder" v-if="trackLeaveOnDays">
                      {{ parseFloat(usedLeaveDays).toFixed(1) }} days
                    </h4>
                    <h4 class="mb-0 font-weight-bolder" v-else>
                      {{
                        parseFloat(usedLeaveHours).toFixed(1)
                      }}hrs
                    </h4>
                    <small>Used</small>
                  </div>
                </div>

                <!-- 03 Remaining -->

                <div class="d-flex align-items-center pr-bx">
                  <div class="ml-1">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <h4 class="mb-0 font-weight-bolder" v-if="trackLeaveOnDays">
                          {{ parseFloat(allocatedLeaveDays - usedLeaveDays).toFixed(1) }}
                          days
                        </h4>
                        <h4 class="mb-0 font-weight-bolder" v-else>
                          {{
                            parseFloat(remainingLeaveHours).toFixed(1)
                          }}hrs
                        </h4>
                      </li>
                    </ul>
                    <small>Remaining</small>
                  </div>
                </div>

                <!-- 04 Leave requests -->

                <div class="d-flex align-items-center ml-1">
                  <b-avatar variant="light-warning" rounded>
                    <feather-icon icon="LoaderIcon" size="18" />
                  </b-avatar>

                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder">
                      {{ leaveRequestCount }}
                    </h4>
                    <small>Leave requests</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right side Downloading button -->

            <b-col cols="2" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ">
              <b-row>
                <div
                  class="d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                >
                  <b-button
                    class="float-right"
                    size="md"
                    variant="primary"
                    @click="requestLeave()"
                    >Request Leave
                  </b-button>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </div>

    <!-- Tables -->

    <div>
      <b-row class="match-height">
        <b-col lg="12">
          <b-card>
            <ScheduledLeaves
              ref="scheduledLeaves"
              @summaryRefresh="getUserSummaryDetails()"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row class="match-height">
        <b-col lg="12">
          <b-card>
            <PastLeaves />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <request-leave
      ref="RequestLeaveSideBar"
      :is-request-leave-sidebar-active="isRequestLeaveSidebarActive"
      @requestLeave="requestLeave()"
    />
  </div>
</template>

<script>
import ScheduledLeaves from './scheduledLeaves';
import PastLeaves from './pastLeaves';
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RequestLeave from '@/views/leave-management/sidebars/requestLeave';
import SalariedStaffLeaveAPI from '@/apis/modules/leave_managment_salaried_staff';
import MomentMixin from '@/mixins/MomentMixin';
import ErrorMessages from '@/mixins/ErrorMessages';
export default {
  mixins: [MomentMixin, ErrorMessages],
  components: {
    ScheduledLeaves,
    PastLeaves,
    RequestLeave,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
  },

  data() {
    return {
      selectedLeave: {},
      isRequestLeaveSidebarActive: false,
      allocatedLeaveDays: 0,
      usedLeaveDays: 0,
      leaveRequestCount: 0,
      allocatedLeaveHours: 0,
      usedLeaveHours: 0,
      remainingLeaveHours: 0,
      sicknessLeaveHours: 0,
      workingHoursPerDay: 0,
      loading: false,
      userDetails: {},
      jobRoleName: '',
      currentPracticeId: '',
      trackLeaveOnDays:''
    };
  },
  watch: {
    isRequestLeaveSidebarActive(val) {
      if (!val) {
        this.getUserSummaryDetails();
        this.$refs['scheduledLeaves'].refreshTable();
      }
    },
  },
  methods: {
    requestLeave() {
      this.isRequestLeaveSidebarActive = !this.isRequestLeaveSidebarActive;
    },
    async getUserSummaryDetails() {
      try {
        this.loading = true;
        const Response = await SalariedStaffLeaveAPI.userSummary();
        let leave_takable_count = Response.data.data.users[0].leave_takable_count;
        let working_hours_per_day = Response.data.data.working_hours_per_day;
        let user_total_leave_per_year = Response.data.data.user_total_leave_per_year;
        let user_total_sickness_hour = Response.data.data.total_sickness_hours_taken;
        let total_work_hours = user_total_leave_per_year * working_hours_per_day;
        let remaing = total_work_hours - leave_takable_count;
        let used_sick_days = parseInt(user_total_sickness_hour / working_hours_per_day);
        let used_days = remaing / working_hours_per_day;

        this.usedLeaveDays = used_days;
        this.allocatedLeaveDays = Response.data.data.users[0].number_of_leaves_per_year;
        let number_of_leaves_per_year = Response.data.data.users[0].number_of_leaves_per_year;
        this.workingHoursPerDay = working_hours_per_day;
        this.allocatedLeaveHours = user_total_leave_per_year * working_hours_per_day;
        this.usedLeaveHours = used_days * working_hours_per_day;
        this.remainingLeaveHours = (number_of_leaves_per_year - used_days) * working_hours_per_day;
        this.sicknessLeaveHours = user_total_sickness_hour;

        this.userDetails = Response.data.data.users[0].user;
        this.currentPracticeId = await Response.data.data.users[0].practice_id;
        this.jobRoleName = Response.data.data.users[0].job_role.name;
        this.leaveRequestCount = Response.data.data.pending_request_count;
        this.sicknessLeaveDays = used_sick_days;
        this.trackLeaveOnDays = !!Response.data.data.practice.track_leave_on_days;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.convertAndNotifyError(error);
      }
    },
  },
  mounted() {
    this.getUserSummaryDetails();
  },
};
</script>
<style></style>
